<template>
  <div class="aaa">
    <v-container>
      <ul>
        <li>
          <a
            href="/documents/zakonkg.pdf"
            target="_blank"
            class="text-decoration-none"
          >
            Закон о товарной бирже и биржевой торговле в Кыргызской Республике
          </a>
        </li>

        <li>
          <a
            href="/documents/Rulesfortradingcommoditysector.pdf"
            target="_blank"
            class="text-decoration-none"
          >
          ПРАВИЛА БИРЖЕВОЙ ТОРГОВЛИ В ТОВАРНО-СЫРЬЕВОМ СЕКТОРЕ ЗАО «КЫРГЫЗСКАЯ
          ФОНДОВАЯ БИРЖА»
          </a>
        </li>

        <li>
          <a
            href="/documents/Закон_КР_О_противодействии_финансированию_террористической_деятельности.pdf"
            target="_blank"
            class="text-decoration-none"
          >
            Закон КР о противодействии финансированию террористической
            деятельности
          </a>
        </li>
        <!-- <li>
          <a
            href="/documents/ПереченьбиржевыхторговторгуемыхнасекцияхЗАОКыргызскаяфондоваябиржа.PDF"
            target="_blank"
            class="text-decoration-none"
          >
            Перечень биржевых товаров, торгуемых на секциях ЗАО «Кыргызская
            фондовая биржа»
          </a>
        </li> -->
        <li>
          <a
            href="/documents/Тарифы_биржевых_сборов_драгмет_21_12_2022.doc"
            target="_blank"
            class="text-decoration-none"
          >
            Тарифы биржевых сборов со сделок, заключаемых на ЗАО «Кыргызская
            фондовая биржа» в секторе драгоценных металлов
          </a>
        </li>
        <li>
          <a
            href="/documents/Тарифы_товарно_сырьевой_сектор_21_12_2022.pdf"
            target="_blank"
            class="text-decoration-none"
          >
            Тарифы биржевых сборов со сделок, заключаемых на ЗАО «Кыргызская
            фондовая биржа» в товарно-сырьевом секторе
          </a>
        </li>
        <li>
          <a
            href="/documents/Положение_о_расчетно_клиринг_операциях_в_ТСС_24.02.2025.pdf"
            target="_blank"
            class="text-decoration-none"
          >
            ПОЛОЖЕНИЕ О РАСЧЕТНО-КЛИРИНГОВЫХ ОПЕРАЦИЯХ В ТОВАРНО-СЫРЬЕВОМ СЕКТОРЕ ЗАО «КЫРГЫЗСКАЯ ФОНДОВАЯ БИРЖА» 
          </a>
        </li>
        <!-- <li>
          <a
            href="/documents/Правила_БТ_ТСС_КФБ_04.07.2022.docx"
            target="_blank"
            class="text-decoration-none"
          >
            Правила БТ ТСС КФБ
          </a>
        </li> -->
        <!-- <li>
          <a
            href="/documents/RulesfortradingpreciousmetalsonKSE.pdf"
            target="_blank"
            class="text-decoration-none"
          >
            Правила торговли драг.мет на КФБ
          </a>
        </li> -->

        <!-- <li>
          <a href="/documents/kfb.pdf" target="_blank" class="text-decoration-none">
             КФБ площадка для привлечения инвестиций в компании
          </a>
        </li> -->
      </ul>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.aaa {
  height: 50vh;
}
</style>