import { render, staticRenderFns } from "./View.vue?vue&type=template&id=0b7882a4"
import script from "./View.vue?vue&type=script&lang=js"
export * from "./View.vue?vue&type=script&lang=js"
import style0 from "quill/dist/quill.snow.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../assets/vue2-editor.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports